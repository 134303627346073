import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { SnackBar } from "@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { ToggleButton } from "@evabot/eva-react-core-library/dist/atoms/ToggleButton/ToggleButton";
import { ModalWrapper } from "@evabot/eva-react-core-library/dist/molecules/ModalWrapper/ModalWrapper";
import { useState } from "react";
import { axiosHelperCore } from "utils";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { Tag } from "@evabot/eva-react-core-library/dist/molecules/Tag/Tag";
import { Backdrop } from "@mui/material";

function SettingDataBody({index, item, getSavedSequenceList, handleEdit}) {

    const [isDeleteConfirmationShow, setIsDeleteConfirmationShow] = useState(false);
    const [isPreview, setIsPreview] = useState(false);
    const [sequenceStepList, setSequenceStepList]=useState([]);
    const [loading,setLoading]=useState(false);
    const onDeleteSetting = async () => {
        const result: any = await axiosHelperCore({
            url: `rapport/sequence/updateSequenceActivateSetting`,
            method: "POST",
            JSONData: { userId : item?.userId, sequenceId: item.sequenceId, activated:!item.activated}
        });
        setIsDeleteConfirmationShow(false);
        getSavedSequenceList();
    }

    const handleSequenceEdit = async() => {
        handleEdit(item?.sequenceId);
    }

    const generateSequence = async()=>{
        setLoading(true);
        const result: any = await axiosHelperCore({
            url: `rapport/sequence/generateSequence`,
            method: "POST",
            JSONData: { sequenceId:item?.sequenceId }
        });
        setSequenceStepList(result.data);
        setIsPreview(true);
        setLoading(false);
        console.log("generateSequencegenerateSequencegenerateSequence",result.data);
    }

    return (
        <>
         {loading && <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }}
        open={true}>
        <Icon name="loadingIcon" />
      </Backdrop>}
            <Flex
                padding={"15px"}
                fontSize="12px"
                fontWeight={500}
                position="sticky"
                backgroundColor={index % 2 == 0 ? "#FFF" : "rgb(249, 249, 249)"}
                alignItems={"center"}
                justifyContent={"center"}
              
            >
                <Flex padding={"0 5px"} textWrap="balance" width="5%" >
                    {index + 1}
                </Flex>

                <Flex style={{ "wordBreak": "break-word" }} padding={"0 5px"} width="13%">
                    {item?.sequenceName}
                </Flex>
                <Flex style={{ "wordBreak": "break-word" }} padding={"0 5px"} width="12%" >
                    {item?.numberOfSteps}
                </Flex>
                <Flex style={{ "wordBreak": "break-word" }} padding={"0 5px"} width="10%" >
                    {item?.integrationType === 'outreach' && <Tag
                        color="#60C678"
                        bgColor="rgba(239, 249, 241, 1)"
                        text={item?.integrationType}
                    />}
                    {item?.integrationType === 'manual' && <Tag
                        color="#0094E3"
                        bgColor="rgba(220, 249, 255, 1)"
                        text={item?.integrationType}
                    />}
                </Flex>
                <Flex style={{ "wordBreak": "break-word" }} padding={"0 5px"} width="13%" >
                    {item?.activatedBy}
                </Flex>
                <Flex style={{ "wordBreak": "break-word" }} padding={"0 5px"} width="12%" >
                    {item?.activatedOn}
                </Flex>
                <Flex style={{ "wordBreak": "break-word" }} justifyItems={"center"} justifyContent={"center"} padding={"0 5px"} gap={"10px"} width="15%" >

                    <ToggleButton
                        value={item?.activated}
                        name='isActive'
                        variant="tertiary"
                        onChange={(value) => { setIsDeleteConfirmationShow(true)}}
                    />
                   
                </Flex>
                <Flex style={{ "wordBreak": "break-word" }} padding={"0 5px"} gap={"10px"} width="20%">
                <Button variant="secondary" padding={"12px"} fontSize={"14px"} size="sm" disabled={!item?.activated} onClick={handleSequenceEdit}>Edit</Button>
                <Button variant="secondary" padding={"12px"} fontSize={"14px"} size="sm" onClick={generateSequence}>Preview</Button>
                </Flex>
            </Flex>
            <ModalWrapper
                width="max-content"
                height="max-content"
                hideModal={!isDeleteConfirmationShow}
                setHideModal={() => {
                    setIsDeleteConfirmationShow(false);
                }}
                bgcolor={"rgba(0, 0, 0, 0.8)"}
                padding="40px"
            >
                <Flex
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <Text fontSize={"20px"} fontWeight={300}>
                        {item.activated?"Are you sure you want to deactivate this Setting":"Are you sure you want to activate this Setting"}
                    </Text>
                    <Flex mt="15px">
                        <Flex
                            background={"#DD015B"}
                            color={"#fff"}
                            fontSize={"16px"}
                            fontWeight={500}
                            p="10px 32px"
                            justifyContent={"center"}
                            alignItems={"center"}
                            borderRadius={"12px"}
                            width={"max-content"}
                            cursor="pointer"
                            onClick={() => { onDeleteSetting() }}
                        >
                            Yes
                        </Flex>
                        <Flex
                            ml="16px"
                            border="1px solid #0094E3"
                            background={"#fff"}
                            color={"#0094E3"}
                            fontSize={"16px"}
                            fontWeight={500}
                            p="10px 32px"
                            justifyContent={"center"}
                            alignItems={"center"}
                            borderRadius={"12px"}
                            width={"max-content"}
                            cursor="pointer"
                            onClick={() => { setIsDeleteConfirmationShow(false) }}
                        >
                            Cancel
                        </Flex>
                    </Flex>
                </Flex>
            </ModalWrapper>
            <ModalWrapper
                width="70%"
                height="90%"
                hideModal={!isPreview}
                setHideModal={() => {
                    setIsPreview(false);
                }}
                bgcolor={"rgba(0, 0, 0, 0.8)"}
                padding="0"
                overflow ={"scroll"}
                
                
            >
                <Flex
                    flexDirection={"column"}
                    backgroundColor={"rgb(243 245 251)"}
                    padding={"25px"}
                    margin={"0"}
                    minHeight="100%"
                    >
                   <Flex margin={"0"} padding={"0"} justifyContent={"left"} width={"100%"} className="text-left text-[20px]">Sequence Preview ({item?.sequenceName})</Flex>
                   {
                    sequenceStepList.map(s=>( 
                    <Flex flexDirection={"column"} className="mt-3 px-3 py-3 border border-white-500 rounded">
                        <div className="text-[18px]">{s.stepName}</div>
                        { s.content1 && <div className="mt-2">
                            <div  className="text-[16px] mt-2" >Subject&nbsp;<span className="text-[#DD015B]">({s.field1})</span></div>
                            <div className="bg-[#fff] px-2 py-2 mt-2 rounded">{s.content1}</div>
                        </div>}
                        <div className="mt-2">
                        <div className="text-[16px] mt-2">Body&nbsp;<span className="text-[#DD015B]">({s.field2})</span></div>
                        <div className="bg-[#fff] px-2 py-2 mt-2 rounded">{s.content2}</div>
                        </div>
                    </Flex>
                ))
                   }
                   
                </Flex>
            </ModalWrapper>
        </>
    );
}

export default SettingDataBody;


// <table className="min-w-full divide-y divide-gray-200 ">

// {
//     sequenceSettingsList.map((setting, index) => (

//         <tbody style={{ padding: "10px !important" }}>
//             <tr className={index % 2 == 0 ? plainCSS : alternateCSS}>
//                 <td className="p-3 py-5 whitespace-no-wrap">Sequence#{index + 1}</td>
//                 <td className="p-3 py-5 whitespace-no-wrap">{setting.word}</td>
//                 <td className="p-3 py-5 whitespace-no-wrap">{setting.tone}</td>
//                 <td className="p-3 py-5 whitespace-no-wrap">{frameworkList.find(f => f.frameworkId === setting.frameworkId)?.name}</td>
//                 <td className="p-3 py-5 whitespace-no-wrap">{contextList.find(c => c.contextId === setting.contextId)?.name}</td>
//                 <td className="p-3 py-5 whitespace-no-wrap cursor-pointer">
                   
//                 </td>
//             </tr>

//         </tbody>))}
// </table>