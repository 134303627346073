import React, { useState, CSSProperties, useEffect } from 'react';
import { TextField, Select, MenuItem, IconButton, FormControl, InputLabel, Autocomplete, Box, Typography, InputAdornment,  Tooltip } from '@mui/material';
import { ModalWrapper } from "@evabot/eva-react-core-library/dist/molecules/ModalWrapper/ModalWrapper";
import { ToggleButton } from '@evabot/eva-react-core-library/dist/atoms/ToggleButton/ToggleButton';
import { SnackBar } from '@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar';
import { Button } from '@evabot/eva-react-core-library/dist/atoms/Button/Button';
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import { Link } from 'react-router-dom';
import { uuidv4 } from "utils/cookie";

// Initial options and lists
const linkedinOptions = [
  { value: 'linkedinConnectionRequest', label: 'LinkedIn Connection Request' },
  { value: 'linkedinDirectMessage', label: 'LinkedIn Direct Message' },
  { value: 'linkedinInMail', label: 'LinkedIn InMail' }
];

const emailStepOptions = [{ value: 'email-1', label: 'Email 1' }, { value: 'email-2', label: 'Email 2' }];
const followUpOptions = [{ value: 'followUp-1', label: 'FollowUp Email 1' }];


const ManualSequenceBuilder = ({frameworkList, defaultEmail, defaultLinkedin, sequenceName, toneList, wordCountList, contextList, onBack, handleSequenceSteps}) => {
    const selectedClass = "bg-[#0094E3] px-3 py-2 border rounded-[30px] border-gray-200 text-[#fff] text-[13px]";
    const notSelectedClass = "bg-[white] px-3 py-2 border rounded-[30px] border-gray-200 text-[#000] text-[13px]"; 

    const [followUpOptionsState, setFollowUpOptionsState] = useState(followUpOptions);
    const [isDeleteConfirmationShow, setIsDeleteConfirmationShow] = useState(false);
    const [emailOptions, setEmailOptions] = useState(emailStepOptions);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [sequenceSteps, setSequenceSteps] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isActivated, setIsActivated] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorDetail, setErrorDetail] = useState('');
    const [showError, setShowError] = useState(false);

    // validate sequence form
    const validateSteps = () => {
        const fieldValues = new Set();
        const emailValues = new Set();
        const followUpValues = new Set();
        let errorDetails = '';
    
        for (const stepId in sequenceSteps) {
            const stepData = sequenceSteps[stepId];

            if(stepData.activated){

                // Check if stepType exists
                if (!stepData.stepType) {
                    errorDetails = "Please select step type.";
                    const element = "id_" + stepId;
                    return { element, errorDetails };
                }
        
                // Email validations
                if (stepData.stepType.includes('email')) {
                    if (!stepData.customFields.emailSubject || !stepData.customFields.emailBody) {
                        errorDetails = "";
                        const element = "id_" + stepId;
                        return { element, errorDetails };
                    }
                    if (emailValues.has(stepData.stepType)) {
                        errorDetails = `Step "${stepData.stepName}" has duplicate email type "${stepData.stepType}".`;
                        const element = "id_" + stepId;
                        return { element, errorDetails };
                    }
                    emailValues.add(stepData.stepType);
                }
        
                // Follow-up validations and presence of corresponding email step
                if (stepData.stepType.includes('followUp')) {
                    if (!stepData.customFields.emailBody) {
                        errorDetails = "";
                        const element = "id_" + stepId;
                        return { element, errorDetails };
                    }
        
                    const correspondingEmailStep = `email-${stepData.stepType.split('-')[1]}`;
                    if (!emailValues.has(correspondingEmailStep)) {
                        errorDetails = `Corresponding email step "${correspondingEmailStep}" must be present before "${stepData.stepName}".`;
                        const element = "id_" + stepId;
                        return { element, errorDetails };
                    }
        
                    if (followUpValues.has(stepData.stepType)) {
                        errorDetails = `Step "${stepData.stepName}" has duplicate follow-up type "${stepData.stepType}".`;
                        const element = "id_" + stepId;
                        return { element, errorDetails };
                    }
                    followUpValues.add(stepData.stepType);
                }
        
                // LinkedIn InMail validations
                if (stepData.stepType.includes('linkedinInMail')) {
                    if (!stepData.customFields.linkedinSubject || !stepData.customFields.linkedinBody) {
                        errorDetails = "";
                        const element = "id_" + stepId;
                        return { element, errorDetails };
                    }
                }
        
                // LinkedIn Direct Message and Connection Request name validation
                if (stepData.stepName.includes('Connection Request') && stepData.stepType !== 'linkedinConnectionRequest') {
                    errorDetails = `Step "${stepData.stepName}" must have type "LinkedIn Connection Request".`;
                    const element = "id_" + stepId;
                    return { element, errorDetails };
                }
        
                if (stepData.stepName.includes('Send Message') && stepData.stepType !== 'linkedinDirectMessage') {
                    errorDetails = `Step "${stepData.stepName}" must have type "LinkedIn Direct Message".`;
                    const element = "id_" + stepId;
                    return { element, errorDetails };
                }

                if (stepData.stepType.includes('linkedinDirectMessage') || stepData.stepType.includes('linkedinConnectionRequest')) {
                    if (!stepData.customFields.linkedinBody) {
                        errorDetails = "";
                        const element = "id_" + stepId;
                        return { element, errorDetails };
                    }
                }
        
                // Check for duplicate custom field values
                const fieldsToCheck = [
                    stepData.customFields.emailSubject?.trim(),
                    stepData.customFields.emailBody?.trim(),
                    stepData.customFields.linkedinBody?.trim(),
                    stepData.customFields.linkedinSubject?.trim()
                ];
        
                for (const field of fieldsToCheck) {
                    if (field && fieldValues.has(field)) {
                        errorDetails = `Step "${stepData.stepName}" - Similar custom field name used in same or other step. Please check.`;
                        const element = "id_" + stepId;
                        return { element, errorDetails };
                    }
                    if (field) fieldValues.add(field);
                }
            }
        }
    
        // Return null if no errors found
        return null;
    };
    

    // Handle adding a new sequence step
    const addSequenceStep = () => {
        const newStep = {
            stepName: '',
            stepType: '',
            customFields: {},
            framework: '',
            tone: '',
            wordCount: '',
            context: '',
            activated: true
        };
        setSequenceSteps([...sequenceSteps, newStep]);
    };

    const inputDisabled = (index: number): CSSProperties => {
        return {
            opacity: sequenceSteps[index]?.activated ? 1 : 0.5,
            pointerEvents: sequenceSteps[index]?.activated ? 'auto' : 'none' as 'none',
        };
    };

    // Handle step change (custom fields, step type etc.)
    const handleStepChange = (index, field, value) => {
        const updatedSteps = [...sequenceSteps];
        updatedSteps[index][field] = value;

        if(field === 'framework' && value === ''){
            updatedSteps[index]['tone'] = toneList.find(tone => tone?.value === defaultEmail?.tone).toneId;
        }

        // If an email option is selected, expose the next follow-up option
        if (field === 'stepType') {
            if(value.includes('email')){
                const checkIndex = value.split("-");
                updatedSteps[index]['stepName'] = `Step ${index+1} (Email ${checkIndex[1]})`;
                updatedSteps[index]['framework'] = defaultEmail?.frameworkId;
                updatedSteps[index]['tone'] = '';
                updatedSteps[index]['wordCount'] = wordCountList.find(wordLimit => wordLimit?.value === defaultEmail?.wordLimit).wordId;
            }

            if(value.includes('followUp')){
                const checkIndex = value.split("-");
                updatedSteps[index]['stepName'] = `Step ${index+1} (FollowUp Email ${checkIndex[1]})`;
                updatedSteps[index]['framework'] = '';
                updatedSteps[index]['wordCount'] = '';
                updatedSteps[index]['tone'] = '';
            }

            if(value === 'linkedinDirectMessage'){
                updatedSteps[index]['stepName'] = `Step ${index+1} (${linkedinOptions.find(option => option.value === value)?.label}})`;
                updatedSteps[index]['framework'] = '';
                updatedSteps[index]['wordCount'] = wordCountList.find(wordLimit => wordLimit?.value === defaultLinkedin?.linkedinDirectMessage?.wordLimit).wordId;
                updatedSteps[index]['tone'] = toneList.find(tone => tone?.value === defaultLinkedin?.linkedinDirectMessage?.tone).toneId;
            }

            if(value === 'linkedinConnectionRequest'){
                updatedSteps[index]['stepName'] = `Step ${index+1} (${linkedinOptions.find(option => option.value === value)?.label}})`;
                updatedSteps[index]['framework'] = '';
                updatedSteps[index]['tone'] = toneList.find(tone => tone?.value === defaultLinkedin?.linkedinConnectionRequest?.tone).toneId;
            }

            if(value === 'linkedinInMail'){
                updatedSteps[index]['stepName'] = `Step ${index+1} (${linkedinOptions.find(option => option.value === value)?.label}})`;
                updatedSteps[index]['framework'] = defaultEmail?.frameworkId;
                updatedSteps[index]['wordCount'] = wordCountList.find(wordLimit => wordLimit?.value === defaultEmail?.wordLimit).wordId;
            }
            const isEmail = value.startsWith('email');
            if (isEmail) {
                const followUpIndex = value.split('-')[1];
                const newFollowUpLabel = `FollowUp Email ${followUpIndex}`;
                const newFollowUpValue = `followUp-${followUpIndex}`
                setFollowUpOptionsState(prev => {
                if (!prev.find(option => option.value === newFollowUpValue)) {
                    return [...prev, { value: newFollowUpValue, label: newFollowUpLabel }];
                }
                return prev;
                });

                // Expose next email option
                const nextEmailIndex = parseInt(followUpIndex) + 1;
                setEmailOptions(prev => {
                if (!prev.find(option => option.value === `email-${nextEmailIndex}`)) {
                    return [...prev, { value: `email-${nextEmailIndex}`, label: `Email ${nextEmailIndex}` }];
                }
                return prev;
                });
            }
        }

        setSequenceSteps(updatedSteps);
    };

    // Handle form changes for custom fields
    const handleCustomFieldChange = (index, field, value) => {
        const updatedSteps = [...sequenceSteps];
        updatedSteps[index].customFields[field] = value;
        setSequenceSteps(updatedSteps);
    };

    const onDeleteSetting = async () => {
        const updatedSteps = [...sequenceSteps];
        updatedSteps[selectedIndex].activated = !updatedSteps[selectedIndex]?.activated;
        setIsDeleteConfirmationShow(false);
        setSequenceSteps(updatedSteps);
    }

    const handleSubmitSequence = () => {
        setIsSubmitted(true);

        const status = validateSteps();
        if (status && (status?.element || status?.errorDetails?.trim() != "")) {
            document.getElementById(status?.element).scrollIntoView({ behavior: 'smooth' });
            setErrorMessage("Please check all the errors");
            setShowError(true);
            setErrorDetail(status?.element+"$$$"+status.errorDetails);
            return;
        }

        const formattedData = Object.keys(sequenceSteps).map((stepId, index) => {
            const stepData = sequenceSteps[stepId];
            let field1 = "";
            let field2 = "";
            let type = "";
      
            if (stepData?.stepType.includes('email') || stepData?.stepType.includes('followUp')) {
              field1 = stepData?.customFields?.emailSubject || '';
              field2 = stepData?.customFields?.emailBody || '';
              type = stepData?.stepType;
            } else if (stepData.stepType.includes('linkedin')) {
              field1 = stepData?.customFields?.linkedinSubject || '';
              field2 = stepData?.customFields?.linkedinBody || '';
              type = stepData?.stepType;
            }
      
            return {
              crmStepId: uuidv4(),
              stepName: stepData.stepName,
              stepType: stepData.stepType,
              type,
              orderBy: index+1,
              field1,
              field2,
              frameworkId: stepData?.framework || '',
              frameworkName: frameworkList.filter(fw => fw.frameworkId).find(fw => fw.frameworkId === stepData.framework)?.name || '',
              framework: frameworkList.filter(fw => fw.frameworkId).find(fw => fw.frameworkId === stepData.framework)?.framework || '',
              contextId: stepData?.context || '',
              contextName: contextList.filter(fw => fw.contextId).find(fw => fw.contextId === stepData.context)?.name || '',
              context: contextList.filter(fw => fw.contextId).find(fw => fw.contextId === stepData.context)?.context || '',
              toneId: stepData?.tone,
              tone: toneList.find(tone => tone.toneId === stepData.tone)?.value || '',
              wordId: stepData?.wordCount,
              wordLimit: wordCountList.find(wc => wc.wordId === stepData.wordCount)?.value || '',
              activated: stepData?.activated,
            };
        });

        console.log(formattedData);

        handleSequenceSteps(formattedData, sequenceName);
    }

    useEffect(() => {
        setSequenceSteps([{
            stepName: 'Step 1 (Email 1)',
            stepType: emailStepOptions ? emailStepOptions.find(item => item.value === 'email-1')?.value : '',
            customFields: {},
            framework: defaultEmail?.frameworkId || '',
            tone: defaultEmail ? toneList.find(tone => tone.value === defaultEmail.tone)?.toneId : '',
            wordCount: defaultEmail ? wordCountList.find(wordLimit => wordLimit.value === defaultEmail.wordLimit)?.wordId : '',
            context: '',
            activated: true
        }]);
    }, [emailStepOptions]);

  return (
    <div style={{ padding: '20px' }}>
        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center", marginBottom: "15px" }}>
            <div style={{ fontSize: "24px" }}>{sequenceName}</div>
            <div style={{ justifyContent: "flex-end" }}><Button iconName="arrowLeft" onClick={onBack} variant="secondary">Back</Button></div>
        </div>
        <SnackBar
            iconName={"info"}
            message={errorMessage}
            setShowSnackBar={setShowError}
            show={showError}
            timeout={8000}
            anchor={"top-right"}
            type={'error'}
        />

      {/* Sequence Steps */}
      {sequenceSteps.map((step, index) => (
        <div
          id={"id_" + index}
          key={index}
          style={{
            margin: '20px 0',
            padding: '20px',
            border: '1px solid #ddd',
            borderRadius: '10px',
            backgroundColor: '#f9f9f9',
            display : 'flex',
            flexDirection : 'column',
            gap : '10px',
            opacity: `${!step?.activated ? '0.6' : '1'}`
          }}
        >
           { errorDetail && isSubmitted && errorDetail.split("$$$")[0]==="id_" + index && <div className="text-red-500 text-[12px]">{errorDetail.split("$$$")[1]}</div>}
           <div style={{ display : "flex", justifyContent : "space-between"}}>

                {/* Step Name */}
                <div style={{ marginBottom: '10px' }}>
                    <Typography fontSize={"20px"}>Step {index + 1} {(() => {
                        const label = followUpOptionsState.find(option => option.value === step.stepType)?.label ||
                                        emailOptions.find(option => option.value === step.stepType)?.label ||
                                        linkedinOptions.find(option => option.value === step.stepType)?.label;
                        return label ? ` (${label})` : '';
                        })()}
                    </Typography>
                </div>
                {/* Enable/Disable Icon */}
                <Tooltip title={"Disabling this step will remove it from the sequence and prevent output generation"} placement="top-start" disableHoverListener={!step?.activated} arrow>
                    <div>
                    <ToggleButton
                        value={step?.activated}
                        name='activated'
                        variant="tertiary"
                        onChange={(value) => {
                            setSelectedIndex(index);
                            setIsDeleteConfirmationShow(true);
                        }}
                    />
                    </div>
                </Tooltip>
            </div>

            {step?.activated && <div style={inputDisabled(index)}>        
                {/* Step Type */}       
                <FormControl margin="normal" style={{ width: "150px" }}>
                    <InputLabel id="select-type-label">Select Type</InputLabel>
                    <Select
                        labelId="select-type-label"
                        label="Select Type"
                        name='stepType'
                        value={step.stepType}
                        style={{ width: "200px" }}
                        onChange={(e) => handleStepChange(index, 'stepType', e.target.value)}
                    >
                        {emailOptions?.map((option) => (
                        <MenuItem
                            key={option.value}
                            value={option.value}
                        >
                            {option.label}
                        </MenuItem>
                        ))}
                        {followUpOptionsState?.map((option) => (
                        <MenuItem
                            key={option.value}
                            value={option.value}
                        >
                            {option.label}
                        </MenuItem>
                        ))}
                        {linkedinOptions?.map((option) => (
                        <MenuItem
                            key={option.value}
                            value={option.value}
                        >
                            {option.label}
                        </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <div className="flex items-start input-fields col my-1" style={{ alignItems: 'flex-start', gap: '1rem' }}>
                    {/* custom email field */}
                    {step?.stepType?.includes('email') && (
                    <>
                        <div className='w-1/2'>
                        <TextField
                            label="Email Subject Custom Field"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            name="emailSubject"
                            value={step.customFields.emailSubject}
                            onChange={(e) => handleCustomFieldChange(index, 'emailSubject', e.target.value)}
                            required
                            InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                <Tooltip title="Custom field name should be unique.">
                                    <IconButton>
                                    <InfoIcon color="primary" />
                                    </IconButton>
                                </Tooltip>
                                </InputAdornment>
                            ),
                            }}
                        />
                        {!step.customFields.emailSubject?.trim() && isSubmitted && <div className="text-red-500 text-[12px]">subject is required</div>}
                        </div>
                        <div className='w-1/2'>
                        <TextField
                            label="Email Body Custom Field"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            name="emailBody"
                            value={step.customFields.emailBody}
                            onChange={(e) => handleCustomFieldChange(index, 'emailBody', e.target.value)}
                            required
                            InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                <Tooltip title="Custom field name should be unique.">
                                    <IconButton>
                                    <InfoIcon color="primary" />
                                    </IconButton>
                                </Tooltip>
                                </InputAdornment>
                            ),
                            }}
                        />
                        {!step.customFields.emailBody?.trim() && isSubmitted && <div className="text-red-500 text-[12px]">email body is required</div>}
                        </div>
                    </>
                    )}

                    {/* custom linkedinInMail field */}
                    {step?.stepType?.includes('linkedinInMail') && (
                    <>
                        <div className='w-1/2'>
                        <TextField
                            label="Linkedin Subject Custom Field"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            name="linkedinSubject"
                            value={step.customFields.linkedinSubject}
                            onChange={(e) => handleCustomFieldChange(index, 'linkedinSubject', e.target.value)}
                            required
                            InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                <Tooltip title="Custom field name should be unique.">
                                    <IconButton>
                                    <InfoIcon color="primary" />
                                    </IconButton>
                                </Tooltip>
                                </InputAdornment>
                            ),
                            }}
                        />
                        {!step.customFields.linkedinSubject?.trim() && isSubmitted && <div className="text-red-500 text-[12px]">linkedin subject is required</div>}
                        </div>
                        <div className='w-1/2'>

                        <TextField
                            label="Linkedin Body Custom Field"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            name="linkedinBody"
                            value={step.customFields.linkedinBody}
                            onChange={(e) => handleCustomFieldChange(index, 'linkedinBody', e.target.value)}
                            required
                            InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                <Tooltip title="Custom field name should be unique.">
                                    <IconButton>
                                    <InfoIcon color="primary" />
                                    </IconButton>
                                </Tooltip>
                                </InputAdornment>
                            ),
                            }}
                        />
                        {!step.customFields.linkedinBody?.trim() && isSubmitted && <div className="text-red-500 text-[12px]">linkedin body is required</div>}
                        </div>

                    </>
                    )}

                    {/* custom linkedinDirectMessage and linkedinConnectionRequest field */}
                    {(step?.stepType.includes('linkedinDirectMessage') || step?.stepType.includes('linkedinConnectionRequest')) && (
                    <div style={{ width: "100%" }}>
                        <TextField
                        label={'LinkedIn Custom Field'}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        name="linkedinBody"
                        value={step.customFields.linkedinBody}
                        onChange={(e) => handleCustomFieldChange(index, 'linkedinBody', e.target.value)}
                        required
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end">
                                <Tooltip title="Custom field name should be unique.">
                                <IconButton>
                                    <InfoIcon color="primary" />
                                </IconButton>
                                </Tooltip>
                            </InputAdornment>
                            ),
                        }}
                        />
                        {!step.customFields.linkedinBody?.trim() && isSubmitted && <div className="text-red-500 text-[12px]">linkedin body is required</div>}
                    </div>
                    )}

                    {/* custom followUp field */}
                    {step?.stepType.includes('followUp') && (
                    <>
                        <div style={{ width: "100%" }}>
                        <TextField
                            label="Follow Up Email Body Custom Field"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            name="emailBody"
                            value={step.customFields.emailBody}
                            onChange={(e) => handleCustomFieldChange(index, 'emailBody', e.target.value)}
                            required
                            InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                <Tooltip title="Custom field name should be unique.">
                                    <IconButton>
                                    <InfoIcon color="primary" />
                                    </IconButton>
                                </Tooltip>
                                </InputAdornment>
                            ),
                            }}
                        />
                        {!step.customFields.emailBody?.trim() && isSubmitted && <div className="text-red-500 text-[12px]">follow up email body is required</div>}
                        </div>
                    </>
                    )}
                </div>

                {/* Framework List */}
                {!step.stepType.includes('followUp') && !(step.stepType === 'linkedinConnectionRequest' || step.stepType === 'linkedinDirectMessage') &&<Autocomplete
                    options={frameworkList}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <TextField {...params} label="Select framework" variant="outlined" />}
                    value={frameworkList.find(fw => fw.frameworkId === step.framework) || null}
                    onChange={(e, newValue) => {
                        handleStepChange(index, 'framework', newValue ? newValue.frameworkId : '');
                    }}
                    style={{ width: "50%", marginTop : "20px" }}
                />}

                <div className='font-normal' style={{ fontSize: "12px", color: "#57585C", display: 'flex', alignItems: 'center', gap: '5px', marginTop: "10px" }}>
                    Want to add a custom framework?<Link to="/frameworkandcontext" style={{ color: "#0094E3", textDecoration: "underline" }}>click here</Link>
                </div>
                {step.framework !== '' && <div style={{ fontSize: "10px", backgroundColor: "#DCF9FF", color: "#57585C", display: 'flex', alignItems: 'center', gap: '10px', marginTop: "10px", border: "1px solid #0094E3", padding: '5px', borderRadius: '5px' }}>
                    {(step?.stepType.includes('email') || step?.stepType.includes('linkedinInMail') || step.framework !== '') && frameworkList.find(fw => fw.frameworkId === step.framework)?.framework}
                </div>}

                {/* Tone List */}
                {!step?.framework && !step.stepType.includes('followUp') && <div className="py-2">
                    <div className="py-2 text-[16px]">Select tone</div>
                    <div className="flex gap-4 cursor-pointer">
                        {toneList?.map((tone) => (
                            <div
                                key={tone.toneId}
                                className={step.tone === tone.toneId ? selectedClass : notSelectedClass}
                                onClick={(e) => handleStepChange(index, 'tone', tone.toneId)}
                            >
                                {tone.name}
                            </div>
                        ))}
                    </div>
                </div>}

                {/* Word Count List */}

                {!(step.stepType === 'linkedinConnectionRequest') && !step.stepType.includes('followUp') && <div className="py-2">
                    <div className="py-2 text-[16px]">Select word limit <span style={{ fontSize: "12px", color: "#57585C" }}>(120 recommended limit)</span></div>
                    <div className="flex gap-2 cursor-pointer">
                    {wordCountList.map((wordCount) => (
                        <div
                        key={wordCount.wordId}
                        className={step.wordCount === wordCount.wordId ? selectedClass : notSelectedClass}
                        onClick={(e) => handleStepChange(index, 'wordCount', wordCount.wordId)}
                        >
                        {wordCount.name}
                        </div>
                    ))}
                    </div>
                </div>}

                {/* Context List (optional) */}
                {!step.stepType.includes('followUp') && step.stepType.includes('email') &&<Autocomplete
                    options={contextList.filter(item => item.type === 'email')}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <TextField {...params} label="Select Context" variant="outlined" />}
                    value={contextList.filter(item => item.type === 'email')?.find(fw => fw.contextId === step.context) || null}
                    onChange={(e, newValue) => {
                        handleStepChange(index, 'context', newValue ? newValue.contextId : '');
                    }}
                    style={{ width: "50%", marginTop : '20px' }}
                />}

                {!step.stepType.includes('followUp') && step.stepType.includes('linkedin') &&<Autocomplete
                    options={contextList.filter(item => item.type === 'linkedin')}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <TextField {...params} label="Select Context" variant="outlined" />}
                    value={contextList.filter(item => item.type === 'linkedin')?.find(fw => fw.contextId === step.context) || null}
                    onChange={(e, newValue) => {
                        handleStepChange(index, 'context', newValue ? newValue.contextId : '');
                    }}
                    style={{ width: "50%", marginTop : '20px' }}
                />}
            </div>}
        </div>
        ))}

      {/* Button to Add Sequence Step and submit sequence*/}
      <div style={{display : 'flex', justifyContent : 'center', gap : '20px'}}>
        <Tooltip title={sequenceName ? '' : 'Sequence Name is mandatory'} placement="top" arrow>
            <span>
                <Button
                    onClick={addSequenceStep}
                    variant={"primary"}
                    padding={"20px"}
                    disabled={sequenceName ? false : true}
                    >
                    Add Sequence Step
                </Button>
            </span>
        </Tooltip>
        <Tooltip title={sequenceName ? (sequenceSteps.length > 0 ? '' : 'Please add step') : 'Sequence Name is mandatory'} placement="top" arrow>
            <span>  
                <Button
                    onClick={handleSubmitSequence}
                    variant={"primary"}
                    padding={"20px"}
                    disabled={sequenceName ? (sequenceSteps.length > 0 ? false : true) : true}
                    >
                    Save Manual Sequence
                </Button>
            </span>
        </Tooltip>
      </div>


      <ModalWrapper
        width="max-content"
        height="max-content"
        hideModal={!isDeleteConfirmationShow}
        setHideModal={() => {
          setIsDeleteConfirmationShow(false);
        }}
        bgcolor={"rgba(0, 0, 0, 0.8)"}
        padding="40px"
      >
        <Flex
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Text fontSize={"20px"} fontWeight={300}>
            {sequenceSteps[selectedIndex]?.activated ? "Are you sure you want to deactivate this Setting" : "Are you sure you want to activate this Setting"}
          </Text>
          <Flex mt="15px">
            <Flex
              background={"#DD015B"}
              color={"#fff"}
              fontSize={"16px"}
              fontWeight={500}
              p="10px 32px"
              justifyContent={"center"}
              alignItems={"center"}
              borderRadius={"12px"}
              width={"max-content"}
              cursor="pointer"
              onClick={() => { onDeleteSetting() }}
            >
              Yes
            </Flex>
            <Flex
              ml="16px"
              border="1px solid #0094E3"
              background={"#fff"}
              color={"#0094E3"}
              fontSize={"16px"}
              fontWeight={500}
              p="10px 32px"
              justifyContent={"center"}
              alignItems={"center"}
              borderRadius={"12px"}
              width={"max-content"}
              cursor="pointer"
              onClick={() => { setIsDeleteConfirmationShow(false) }}
            >
              Cancel
            </Flex>
          </Flex>
        </Flex>
      </ModalWrapper>
    </div>
  );
};

export default ManualSequenceBuilder;
