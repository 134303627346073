import { useEffect, useState } from "react";
import { AeReturnJourneyLayout } from "@evabot/eva-react-core-library/dist/templates/AeReturnJourneyLayout/AeReturnJourneyLayout";
import { AeSidebarSection } from "components/AeSidebar/AeSidebarSection";
import { AeSidebarSectionCollapse } from "components/AeSidebar/AeSidebarSectionCollapse";
import ProfileModalData from "containers/AEReturnDashboard/ProfileModalData";
import { useAppSelector, useAppDispatch } from "hooks";
import { setGlobalStateRole } from "store/globalStateReducer";
import AeReturnIntegrationRightSection from "./AeReturnIntegrationRightSection";
import { useLocation, useNavigate } from "react-router-dom";
import userService from "services/userService";
import { aeReturnProfileData } from "containers/AEReturnProfile/store/aeReturnProfileReducer";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { Backdrop } from "@mui/material";
import { axiosHelperCore } from "utils";

function AeReturnIntegration() {
  const dispatch = useAppDispatch();
  const profileData = useAppSelector((state) => state.profileState.profileData);
  const [openModal, setOpenModal] = useState(false);
  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [connector, setConnector] = useState(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const includesSalesloft = location.pathname.toLowerCase().includes("callbacksalesloft");
  const includesOutreach = location.pathname.toLowerCase().includes("callbackoutreach");
  const includesSalesforce = location.pathname.toLowerCase().includes("callbacksalesforce");
  const includesHubspot = location.pathname.toLowerCase().includes("callbackhubspot");
  let isSaveToken = false;

  const saveToken = (code: String) => {
    let refreshTokenType = "salesforce";
    if (includesOutreach) {
      refreshTokenType = "outreach";
    }
    else if (includesSalesloft) {
      refreshTokenType = "salesloft";
    }
    else if (includesHubspot) {
      refreshTokenType = "hubspot";
    }
    if (!isSaveToken) {
      setLoading(true)
      userService.saveRefreshToken(profileData.userId, code, refreshTokenType).then((response) => {
        if (response?.success) {
          dispatch(aeReturnProfileData({ ...profileData, refreshTokenType, refreshToken: refreshTokenType }));
          navigate("/integrations");
        }
        setLoading(false);
      });
      isSaveToken = true;
    }
  }


  const getIntegrationConnector = async () => {
    const result: any = await axiosHelperCore({ url: `rapport/user/getIntegrationConnector`, method: "POST" });
    setConnector(result?.data?.connector);
    setLoading(false);

  }

  useEffect(() => {
    getIntegrationConnector();
  }, [])

  useEffect(() => {
    //dispatch(setGlobalStateRole({ journeyName: "ae", journeyPath: "1" }));
    const code = queryParams.get('code');
    if (code && profileData?.userId && (!profileData?.refreshTokenType || profileData?.refreshToken == "" || profileData?.refreshToken != "") && (includesSalesloft || includesOutreach || includesSalesforce || includesHubspot)) {
      saveToken(code);
      //navigate("/integrations");
    }

  }, [profileData]);

  useEffect(() => {
    if (!connector)
      setLoading(true);
    else
      setLoading(false);
  }, [connector])

  return (
    <>
      {loading && <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }}
        open={true}>
        <Icon name="loadingIcon" />
      </Backdrop>}
      <AeReturnJourneyLayout
        as="div"
        left={20}
        right={82}
        leftChildren={<AeSidebarSection activeTab="integration" />}
        leftCollapsedChildren={
          <AeSidebarSectionCollapse activeTab="integration" />
        }
        rightChildren={connector && <AeReturnIntegrationRightSection userData={profileData} connector={connector} setConnector={setConnector} />}
        profileModalChild={<ProfileModalData />}
        imageUrl={profileData?.imageFile}
        value={value}
        setValue={setValue}
        openSearchModal={openModal}
        setOpenSearchModal={setOpenModal}
      // head ='Integrations'
      />
    </>
  );
}

export default AeReturnIntegration;
