/* eslint-disable no-undef */
import React, { useEffect, useRef, useState } from 'react';
import confetti from 'canvas-confetti';
import './HeaderMessage.css';

const HeaderMessage = () => {
  const [showMessage, setShowMessage] = useState(true);
  const confettiCanvasRef = useRef(null);

  // Confetti animation function
  const startConfetti = () => {
    const duration = 10 * 1000;
    const animationEnd = Date.now() + duration;
    const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 10000 };

    const myConfetti = confetti.create(confettiCanvasRef.current, {
      resize: true, 
      useWorker: false,
    });

    function randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    }

    const interval = setInterval(() => {
      const timeLeft = animationEnd - Date.now();
      if (timeLeft <= 0) {
        clearInterval(interval);
        return;
      }

      const particleCount = 50 * (timeLeft / duration);

      myConfetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
        })
      );

      myConfetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
        })
      );
    }, 250);
  };

  useEffect(() => {
    const messageSeen = sessionStorage.getItem('rebrandingMessageSeen');
    if (messageSeen) {
      setShowMessage(false);
    } else {
      startConfetti();
      const timer = setTimeout(() => {
        closeMessage();
      }, 20000);

      return () => clearTimeout(timer);
    }
  }, []);

  const closeMessage = () => {
    setShowMessage(false);
    sessionStorage.setItem('rebrandingMessageSeen', 'true');
  };

  if (!showMessage) return null;

  return (
    <div className="header-message">
      <canvas ref={confettiCanvasRef} className="confetti-canvas" />

      <h1 style={{"fontSize" : "18px", fontWeight : "700"}}>Welcome to Our New Look!</h1>
      <p style={{fontWeight : "300"}}>
        We've redesigned our logo to symbolize the future—where humans and AI work together. The heart and neural network represent this vision. <br />It's still the platform you know and love, with a fresh new look to inspire. We'd love to hear your thoughts!
      </p>
      <button onClick={closeMessage} className="close-btn">
        x
      </button>
    </div>
  );
};

export default HeaderMessage;
